import { MenuConfig } from '@models/menu-config';
import { SidebarNavigationLink } from '@models/sidebar-navigation';
import { UserRoles } from '@models/user-roles';
import { getCommonLink, title } from './sidebar-commons';

export function getAdminSidebarNavigationLink(
  menuConfig: MenuConfig,
  haveManyProfile: boolean,
  role: UserRoles,
): SidebarNavigationLink[] {
  let menu: SidebarNavigationLink[] = [
    title('Menu - Compte'),
    {
      type: 'link',
      label: "Vue d'ensemble",
      url: ['/computed-stats/family-space-condolences'],
      icon: 'research',
      activeLink: [
        `/computed-stats/family-space-condolences`,
        `/computed-stats/digital-performances`,
        `/computed-stats/google-performances`,
        `/computed-stats/google-ads`,
      ],
    },
    ...getCommonLink(menuConfig, null, haveManyProfile, role),
  ];
  if (role === UserRoles.SUPER_ADMIN || haveManyProfile) {
    if (menuConfig.reviews) {
      menu.push({
        type: 'link',
        label: 'Avis clients',
        url: [`/reviews/manage`],
        icon: 'review',
        subNav: [
          {
            type: 'link',
            label: 'Gérer ses avis',
            url: [`/reviews/manage`],
            icon: 'manege-review',
          },
          {
            type: 'link',
            label: 'Collecter des avis',
            url: [`/reviews/collect`],
            icon: 'collect-review',
          },
        ],
      });
    }
  }

  menu = menu.concat([
    title('Gestion du compte'),
    {
      type: 'link',
      label: 'Clients',
      url: ['/customer'],
      icon: 'client',
      requiredRoles: [UserRoles.SUPER_ADMIN],
    },
    {
      type: 'link',
      label: 'Gérer les profils',
      url: ['/profile/list'],
      icon: 'profile',
    },
    {
      type: 'link',
      label: 'Boutiques',
      url: ['/shop'],
      icon: 'store',
      requiredRoles: [UserRoles.SUPER_ADMIN],
      subNav: [
        {
          type: 'link',
          label: 'Fleurs',
          url: ['/shop/flowers'],
          icon: 'flower-shop',
          subNav: [
            {
              type: 'link',
              label: 'Produits',
              url: ['/shop/flowers/product'],
              icon: 'flower',
            },
            {
              type: 'link',
              label: 'Catégories',
              url: ['/shop/flowers/category'],
              icon: 'menu',
            },
            {
              type: 'link',
              label: 'Étiquettes',
              url: ['/shop/flowers/label'],
              icon: 'tag',
            },
            {
              type: 'link',
              label: 'Paramètres',
              url: ['/shop/flowers/profile-config'],
              icon: 'management',
            },
          ],
        },
        {
          type: 'link',
          label: 'Forfait entretien',
          url: ['/shop/in-their-memory'],
          icon: 'esm',
          subNav: [
            {
              type: 'link',
              label: 'Produits',
              url: ['/shop/in-their-memory/product'],
              icon: 'esm-product',
            },
            {
              type: 'link',
              label: 'Étiquettes',
              url: ['/shop/in-their-memory/label'],
              icon: 'tag',
            },
          ],
        },
        {
          type: 'link',
          label: 'Arbres',
          url: ['/shop/udiana'],
          icon: 'save-nature',
          subNav: [
            {
              type: 'link',
              label: 'Produits',
              url: ['/shop/udiana/product'],
              icon: 'oak',
            },
            {
              type: 'link',
              label: 'Catégories',
              url: ['/shop/udiana/category'],
              icon: 'menu',
            },
            {
              type: 'link',
              label: 'Étiquettes',
              url: ['/shop/udiana/label'],
              icon: 'tag',
            },
          ],
        },
        {
          type: 'link',
          label: 'Plaques',
          url: ['/shop/grafe'],
          icon: 'plaque',
          subNav: [
            {
              type: 'link',
              label: 'Produits',
              url: ['/shop/grafe/product'],
              icon: 'product',
            },
            {
              type: 'link',
              label: 'Catégories',
              url: ['/shop/grafe/category'],
              icon: 'menu',
            },
            {
              type: 'link',
              label: 'Étiquettes',
              url: ['/shop/grafe/label'],
              icon: 'tag',
            },
          ],
        },
      ],
    },
    {
      type: 'link',
      label: 'Contacts',
      url: ['/contact'],
      icon: 'contact',
    },
    {
      type: 'link',
      label: 'Lieux',
      url: ['/place/list'],
      icon: 'place',
    },
    {
      type: 'link',
      label: 'Utilisateurs',
      url: ['/user'],
      icon: 'user',
      requiredRoles: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN],
    },
    {
      type: 'link',
      label: 'Factures clients',
      url: ['/invoice'],
      icon: 'invoice',
      requiredRoles: [UserRoles.SUPER_ADMIN],
    },
  ]);

  return menu;
}
