import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
/** MODELS */
import { Profile } from '@models/profile.data-list';
/** SERVICES */
import { BaseService } from '@services/base.service';
/** RXJS */
import { Subscription } from 'rxjs';
/** DECORATORS */
import { MatMenuTrigger } from '@angular/material/menu';
import { CustomerConfig } from '@models/customer-config';
import { UserRoles } from '@models/user-roles';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-profile-selector',
  templateUrl: './profile-selector.component.html',
  styleUrls: ['./profile-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileSelectorComponent implements OnInit, OnDestroy {
  static endpoint = '/admin/profile/';
  roles = UserRoles;
  currentProfile: Profile;
  profiles: Profile[];
  term: string;
  unsubscribe: Subscription[] = [];
  customerConfig: CustomerConfig;

  @ViewChild(MatMenuTrigger) menuProfile: MatMenuTrigger;

  constructor(
    private activatedRoute: ActivatedRoute,
    private baseService: BaseService,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {
    const sub = this.router.events
      .pipe(
        distinctUntilChanged((prev, curr) => !!prev && !!curr && prev === curr),
        filter(e => e instanceof NavigationEnd),
        map(() => this.activatedRoute),
        filter(route => !!route.firstChild),
        map(route => route.firstChild.firstChild),
        switchMap(route => route.data),
        filter(value => value !== undefined),
        tap(data => {
          if (data.profile) {
            this.currentProfile = data.profile;
          } else {
            this.currentProfile = null;
          }
          this.profiles = data.profiles ? data.profiles : null;

          this.cdr.detectChanges();
        }),
      )
      .subscribe();

    this.unsubscribe.push(sub);
  }

  ngOnInit(): void {
    this.customerConfig = this.activatedRoute.snapshot.data.customerConfig;
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach(sb => (sb && !sb.closed ? sb.unsubscribe() : null));
  }

  toggleProfileSelector(): void {
    this.menuProfile.toggleMenu();
  }
}
