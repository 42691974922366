import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
/** SERVICES */
/** RXJS */
import { CustomerConfig } from '@models/customer-config';
import { MediaQueriesService } from '@services/media-queries.service';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

/** DECORATORS */

@Component({
  selector: 'app-mlayout',
  templateUrl: './mlayout.component.html',
  styleUrls: ['./mlayout.component.scss'],
})
export class MlayoutComponent implements OnInit, OnDestroy {
  menuOpen = true;
  isMobile = false;
  private unsubscribe: Subscription[] = [];
  customerConfig: CustomerConfig;

  @ViewChild('sidebar') private sidebar;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private mediaQueriesService: MediaQueriesService,
  ) {
    const sub = this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(val => {
      // behavior only on screens < lg
      if (val instanceof NavigationEnd && document.body.offsetWidth <= 992) {
        this.menuOpen = false;
      }
      this.customerConfig = this.activatedRoute.snapshot.data.customerConfig;
    });

    this.unsubscribe.push(sub);
  }

  ngOnInit(): void {
    this.watchMediaQueries();
  }

  watchMediaQueries(): void {
    const sub = this.mediaQueriesService
      .getCurrentMediaQueries()
      .pipe(
        tap(currentMediaQuerie => {
          this.isMobile = currentMediaQuerie === 'sm' || currentMediaQuerie === 'md' ? true : false;
        }),
      )
      .subscribe();
    this.unsubscribe.push(sub);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach(sb => (sb && !sb.closed ? sb.unsubscribe() : null));
  }

  menuClick(): void {
    this.sidebar.toggle();
  }

  menuClose(): void {
    if (this.isMobile) {
      this.sidebar.close();
    }
  }
}
