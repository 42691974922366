import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MheaderComponent } from '@components/mheader/mheader.component';
import { MlayoutComponent } from '@components/mlayout/mlayout.component';
import { MsidebarComponent } from '@components/msidebar/msidebar.component';
import { ProfileSelectorComponent } from '@components/profile-selector/profile-selector.component';
import { SharedModule } from '@modules/shared.module';

@NgModule({
  declarations: [MlayoutComponent, MheaderComponent, ProfileSelectorComponent],
  imports: [SharedModule, MatIconModule, MsidebarComponent],
  exports: [MlayoutComponent, MheaderComponent],
  providers: [],
})
export class MlayoutModule {}
