<mat-toolbar
  class="mat-elevation-z6"
  [style.--button-secondary-background]="customerConfig?.style?.header.background_color"
  [style.--button-secondary-color]="customerConfig?.style?.header.color"
>
  <button aria-label="Menu" class="mheader__menu" mat-icon-button (click)="menuClick.emit()">
    <mat-icon>menu</mat-icon>
  </button>
  <div class="flex-spacer"></div>
  <app-profile-selector></app-profile-selector>
  <div class="flex-spacer"></div>
  <a href="https://24905542.hs-sites-eu1.com/knowledge" mat-icon-button matTooltip="Besoin d'aide" target="_blank">
    <mat-icon class="me-2" fontIcon="help"></mat-icon>
  </a>

  <button color="primary" mat-flat-button [matMenuTriggerFor]="menuSettings" *ngIf="currentUser">
    <ng-container *ngIf="!isMobile">
      {{ currentUser.firstname }} {{ currentUser.lastname }}
      <span class="icon caret profile-selector__icon d-inline-flex mx-1"></span>
    </ng-container>
    <ng-container *ngIf="isMobile">
      <mat-icon class="d-flex align-items-center">person</mat-icon>
    </ng-container>
  </button>
  <mat-menu #menuSettings="matMenu" xPosition="before">
    <a mat-menu-item [routerLink]="['/information']" *appHasRole="[roles.SUPER_ADMIN, roles.ADMIN, roles.STANDARD]"
      >Mes informations</a
    >
    <a mat-menu-item [routerLink]="['/invoice/list']" *appHasRole="[roles.ADMIN]">Factures</a>
    <mat-divider *appHasRole="[roles.SUPER_ADMIN, roles.ADMIN, roles.STANDARD]"></mat-divider>
    <button mat-menu-item (click)="logout()">Déconnexion</button>
  </mat-menu>
</mat-toolbar>
