<div class="msidebar msidebar__content">
  <div class="p-2 msidebar__logo">
    <img alt="FuneUp" src="/assets/imgs/logo-funeup.svg" *ngIf="!customerConfig" />
    <img alt="" [src]="customerConfig.logo.url | apiUrl" *ngIf="customerConfig" />
  </div>
  <mat-nav-list>
    <a mat-list-item [routerLink]="profileId ? ['/', profileId, 'home'] : ['/computed-stats']" *ngIf="isSubcategory()">
      <span class="msidebar__label d-flex align-items-center" matListItemLine>
        <mat-icon class="msidebar__icon me-2" svgIcon="arrow_left"></mat-icon>Retour</span
      >
    </a>
    <ng-container *ngFor="let link of linkTree; let i = index">
      <ng-container [ngSwitch]="link.type">
        <ng-container *ngSwitchCase="'back'">
          <a mat-list-item [routerLink]="link.url">
            <span class="sidebar__label d-flex align-items-center" matListItemLine>
              <mat-icon class="msidebar__icon me-2" svgIcon="arrow_left"></mat-icon>{{ link.label }}</span
            >
          </a>
        </ng-container>
        <ng-container *ngSwitchCase="'title'">
          <div mat-list-item>
            <span class="msidebar__label px-3 mt-2 mb-2 fs-6" matListItemLine>{{ link.label }}</span>
          </div>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <div class="msidebar__title" *ngIf="isSubcategory()">
            <span class="msidebar__label fw-bolder" matSubheader>{{ link.label }}</span>
          </div>

          <ng-container *ngIf="!isSubcategory()">
            <ng-container
              *ngTemplateOutlet="isLink; context: { link: link, collapse: false, collapsed: false }"
            ></ng-container>
          </ng-container>
          <ng-container *ngIf="isSubcategory() && link.subNav">
            <ng-container *ngFor="let subLink of link.subNav">
              <ng-container
                *ngTemplateOutlet="isLink; context: { link: subLink, collapse: false, collapsed: true }"
              ></ng-container>
              <ng-container *ngIf="subLink.subNav">
                <ng-container *ngFor="let subsubLink of subLink.subNav">
                  <ng-container
                    *ngTemplateOutlet="isLink; context: { link: subsubLink, collapse: true, collapsed: false }"
                  ></ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #isLink let-collapse="collapse" let-collapsed="collapsed" let-link="link">
      <ng-container *appHasRole="link.requiredRoles">
        <a
          #rla="routerLinkActive"
          class="msidebar__link"
          mat-list-item
          routerLinkActive
          [class.active]="rla.isActive"
          [class.collapse]="collapse"
          [class.collapsed]="
            collapsed && (rla.isActive || (link.activeLink && link.activeLink.includes(router.url.split('?')[0])))
          "
          [class.not-collapsed]="collapsed && !rla.isActive"
          [class.ps-4]="collapse"
          [queryParams]="link.queryParams"
          [queryParamsHandling]="link.queryParamsHandling"
          [routerLink]="link.url"
          [target]="link.target ? link.target : '_self'"
          (click)="menuClick.emit()"
          *ngIf="link.label !== 'Déposer un avis'"
        >
          <span
            class="indicator"
            [style.background-color]="customerConfig?.style.selectedMenu"
            *ngIf="
              (!collapsed || !link.subNav) &&
              ((link.activeLink && link.activeLink.includes(router.url.split('?')[0])) || rla.isActive)
            "
          ></span>
          <mat-icon class="msidebar__icon me-3" [svgIcon]="link.icon"></mat-icon>
          <span class="msidebar__label flex-grow-1">{{ link.label }}</span>
          <mat-icon
            class="msidebar__icon ms-2"
            [svgIcon]="
              collapsed && (rla.isActive || (link.activeLink && link.activeLink.includes(router.url.split('?')[0])))
                ? 'arrow_down'
                : 'arrow_right'
            "
            *ngIf="link.subNav"
          ></mat-icon>
        </a>
        <a
          #rla="routerLinkActive"
          mat-list-item
          routerLinkActive
          [href]="link.url"
          [target]="link.target ? link.target : '_self'"
          (click)="menuClick.emit()"
          *ngIf="link.label === 'Déposer un avis'"
        >
          <mat-icon class="msidebar__icon me-3" [svgIcon]="link.icon"></mat-icon>
          <span class="msidebar__label flex-grow-1">{{ link.label }}</span>
          <mat-icon class="msidebar__icon ms-2" svgIcon="arrow_right" *ngIf="link.subNav"></mat-icon>
        </a>
      </ng-container>
    </ng-template>
  </mat-nav-list>
</div>
