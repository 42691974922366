import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { User } from '@models/user.data-list';

import { ActivatedRoute } from '@angular/router';
import { CustomerConfig } from '@models/customer-config';
import { UserRoles } from '@models/user-roles';
import { MediaQueriesService } from '@services/media-queries.service';
import { OauthService } from '@services/oauth.service';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-mheader',
  templateUrl: './mheader.component.html',
  styleUrls: ['./mheader.component.scss'],
})
export class MheaderComponent implements OnInit, OnDestroy {
  public currentUser: User;
  public roles = UserRoles;
  unsubscribe: Subscription[] = [];
  customerConfig: CustomerConfig;
  @Output() menuClick = new EventEmitter();
  isMobile = false;

  constructor(
    private oauthService: OauthService,
    private activatedRoute: ActivatedRoute,
    private mediaQueriesService: MediaQueriesService,
  ) {}

  ngOnInit(): void {
    this.customerConfig = this.activatedRoute.snapshot.data.customerConfig;
    this.watchMediaQueries();
    this.initUser();
  }

  watchMediaQueries(): void {
    const sub = this.mediaQueriesService
      .getCurrentMediaQueries()
      .pipe(
        tap(currentMediaQuerie => {
          this.isMobile = currentMediaQuerie === 'sm' ? true : false;
        }),
      )
      .subscribe();
    this.unsubscribe.push(sub);
  }

  initUser(): void {
    const sub = this.oauthService
      .getAuthenticatedUser()
      .pipe(
        filter(u => !!u),
        tap((user: User) => {
          this.currentUser = user;
        }),
      )
      .subscribe();
    this.unsubscribe.push(sub);
  }

  logout(): void {
    this.oauthService.logout();
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach(sb => (sb && !sb.closed ? sb.unsubscribe() : null));
  }
}
