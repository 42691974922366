import { MenuConfig } from '@models/menu-config';
import { Profile } from '@models/profile.data-list';
import { SidebarNavigationLink } from '@models/sidebar-navigation';
import { UserRoles } from '@models/user-roles';

export function title(label: string): SidebarNavigationLink {
  return {
    type: 'title',
    label,
  };
}

export function backLink(label: string, url: string): SidebarNavigationLink {
  return {
    type: 'back',
    label,
    url: [url],
  };
}

export function getCommonLink(
  menuConfig: MenuConfig,
  profile?: Profile,
  haveManyProfile?: boolean,
  role?: UserRoles,
): SidebarNavigationLink[] {
  let menu = [];
  const subMenuShop = [];
  const subMenuEstimate = [];

  if (profile || role === UserRoles.SUPER_ADMIN || haveManyProfile) {
    if (menuConfig.family_space) {
      menu.push({
        type: 'link',
        label: 'Espace famille',
        url: [`${profile ? '/' + profile.id : ''}/family-space-v2`],
        icon: 'family-space',
      });
    }

    if (menuConfig.nimbata) {
      menu.push({
        type: 'link',
        label: 'Appels téléphoniques',
        url: [`${profile ? '/' + profile.id : ''}/call`],
        icon: 'call',
      });
    }

    if (menuConfig.ads || menuConfig.serenite_obseques) {
      if (menuConfig.serenite_obseques) {
        subMenuEstimate.push({
          type: 'link',
          label: 'Devis reçus',
          url: [`${profile ? '/' + profile.id : ''}/estimate`],
          icon: 'estimate',
        });
      }
      if (menuConfig.ads) {
        subMenuEstimate.push({
          type: 'link',
          label: 'Formulaires reçus',
          url: [`${profile ? '/' + profile.id : ''}/enquiry`],
          icon: 'form',
        });
      }
      if (subMenuEstimate.length > 1) {
        menu.push({
          type: 'link',
          label: 'Demande de devis',
          url: [`${profile ? '/' + profile.id : ''}/${menuConfig.serenite_obseques ? 'estimate' : 'enquiry'}`],
          icon: 'estimate',
          subNav: subMenuEstimate,
        });
      } else {
        menu = [...menu, ...subMenuEstimate];
      }
    }

    if (menuConfig.shop || menuConfig.en_sa_memoire /*|| menuConfig.udiana*/) {
      if (menuConfig.shop) {
        subMenuShop.push({
          type: 'link',
          label: 'Fleurs',
          url: [`${profile ? '/' + profile.id : ''}/order`],
          icon: 'flower-bouquet',
        });
      }
      if (menuConfig.en_sa_memoire) {
        subMenuShop.push({
          type: 'link',
          label: 'Entretien de sépultures',
          url: [`${profile ? '/' + profile.id : ''}/in-their-memory`],
          icon: 'funeral',
        });
      }
      if (menuConfig.udiana) {
        subMenuShop.push({
          type: 'link',
          label: 'Arbres hommages',
          url: [`${profile ? '/' + profile.id : ''}/udiana`],
          icon: 'plant-a-tree',
        });
      }
      if (menuConfig.grafe) {
        subMenuShop.push({
          type: 'link',
          label: 'Plaques',
          url: [`${profile ? '/' + profile.id : ''}/grafe`],
          icon: 'award',
        });
      }
      if (menuConfig.tribute_book) {
        subMenuShop.push({
          type: 'link',
          label: 'Livres hommages',
          url: [`${(profile) ? '/' + profile.id : ''}/tribute-book`],
          icon: 'tribute-book',
        });
      }
    }
    menu.push({
      type: 'link',
      label: 'Commandes',
      url: [`${profile ? '/' + profile.id : ''}/${menuConfig.shop ? 'order' : 'in-their-memory'}`],
      icon: 'bag',
      subNav: subMenuShop,
    });
  }

  return menu;
}
